@use 'sass:math'

@import './_variables'

.bar-section-containers
  display: flex
  flex-direction: row
  align-items: center
  gap: $spacing-s
  margin: 0px 0px ($spacing-s + $spacing-xs) ($spacing-m + $spacing-s)
  user-select: none


.bar-section-container
  position: relative
  flex-grow: 0
  display: flex
  align-items: center
  text-align: center
  height: ($font-size-s + $spacing-s)
  border: solid 1px #000000
  border-radius: math.div($font-size-s + $spacing-s, 2)
  background-color: #ffffff
  color: #000000
  font-size: $font-size-s
  line-height: $font-size-s + 2px
  font-weight: 500
  padding: 0px $spacing-s 1px $spacing-s
  cursor: pointer
  margin: -1px 0px 1px 0px

  &.not-selectable
    background-color: transparent
    border-color: $color-muted-grey
    border-style: dotted
    color: $color-muted-grey

  &.is-selected
    background-color: #85bb30
    border-color: #85bb30
    color: #ffffff


