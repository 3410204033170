@import './_variables'

.card-cover
  position: absolute
  top: $spacing-m
  left: $spacing-m
  width: calc(100% - 2 * $spacing-m)
  height: 40vh
  background-color: #fafafafa
  background-position: center center
  background-size: cover
  border-radius: $spacing-l $spacing-l 0px 0px
  overflow: hidden

.card-container
  position: absolute
  width: calc(100% - 2 * $spacing-m)
  height: 100dvh
  overflow-y: auto
  .card-title-and-content
    margin-top: calc(40vh - $spacing-l)
    margin-bottom: $spacing-m
    min-height: 60vh
    background-color: #ffffff
    padding: $spacing-l $spacing-l $spacing-xl $spacing-l
    border-radius: $spacing-l
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.3))
    .card-title
      font-size: $font-size-xl
      font-weight: 600
      line-height: $font-size-xl + 4px
      text-align: center
      margin-bottom: $spacing-l
    .card-content
      font-size: $font-size-m
    
.card-container
    -ms-overflow-style: none  // Internet Explorer 10+
    scrollbar-width: none  // Firefox

.card-container::-webkit-scrollbar
    display: none  // Safari and Chrome

