@import './_variables'

.section
  .section-name
    display: flex
    flex-direction: row
    user-select: none
    margin: 0px $spacing-m
    font-size: $font-size-s

#menu
  .section
    display: flex
    flex-direction: column
    margin-bottom: $spacing-l
    .section-name
      display: flex
      flex-direction: row
      text-transform: uppercase
      font-weight: 500
      margin-bottom: $spacing-m
      .nb-dishes-selected
        margin-left: 10px

#summary
  .section
    display: flex
    flex-direction: column
    .section-name
      flex-grow: 1
      font-size: $font-size-m
      margin-right: $spacing-m
      padding: 0px 0px 1px 0px
    .section-energy
      flex-grow: 0
      flex-shrink: 0
      width: 64px
      text-align: right
      font-size: $font-size-s
      color: $color-muted-grey
      margin-left: auto
    &.bar
      display: flex
      flex-direction: column
      padding-left: $spacing-m
      padding-right: $spacing-m
      gap: $spacing-xs
      .dish-list
        padding-right: 0px !important
        gap: $spacing-xs
        .dish-name
          font-size: $font-size-s


.dish-list
  display: flex
  flex-direction: column
  padding: 0px $spacing-m $spacing-s $spacing-m
  gap: $spacing-s

  &.with-img
    flex-direction: row
    overflow-x: auto

  &::-webkit-scrollbar
    display: none