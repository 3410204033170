@import 'node_modules/bootstrap/scss/functions'
@import 'node_modules/bootstrap/scss/variables'
@import 'node_modules/bootstrap/scss/mixins'

// colors

$white: #ffffff
$black: #000000

$main-background-color: $white
$main-text-color: $black
$divider-color: rgba($black, .14)

$main-text-size: 14px
$main-border-radius: 6px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px

// Custom colors

$color-background: #fafafa
$color-muted-grey: #999999
$color-light-grey: #cccccc
$color-transparent-black: rgba(0,0,0,0.2)

// Custom sizes

$font-size-xs: 11px
$font-size-s: 12px
$font-size-m: 14px
$font-size-l: 16px
$font-size-xl: 20px

$spacing-xs: 4px
$spacing-s: 8px
$spacing-m: 16px
$spacing-l: 32px
$spacing-xl: 48px

$restaurant-header-height: 120px
$footer-height: 50px

// bootstrap-related

// // scss-docs-start container-max-widths
$container-max-width-sm: 575px
$container-max-width-md: 768px
$container-max-width-lg: 992px
$container-max-width-xl: 1200px
$container-max-width-xxl: 1320px
// // scss-docs-end container-max-widths