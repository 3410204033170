@import './_variables'

.dish
  display: flex
  align-items: center
  .dish-name
    flex-grow: 1
    font-size: $font-size-m
    margin: 0px $spacing-m
    padding: 0px 0px 1px 0px
  &.without-img
    flex-direction: row
    .dish-check, .topping
      flex-grow: 0
      flex-shrink: 0
      width: $font-size-m + 4px
      height: $font-size-m + 4px
    .topping
      margin-left: - $font-size-m - 4px
    .check-icon, .plus-sign-icon
      width: 100%
    .plus-sign-icon
      padding: 5px
    .dish-check
      justify-content: center
      border: solid 1px
      border-color: $color-muted-grey
      border-radius: 50%
      margin: 0px 0px 0px $spacing-s
    .plus-sign-icon
      opacity: 0.4
    .check-icon
      visibility: hidden
  &.is-selected
    .dish-check
      border: none
    .check-icon
      visibility: visible
  &.not-selectable
    .dish-check
      border-color: transparent
    .dish-name
      color: $color-muted-grey
      
.dish-energy, .meal-energy, .bar-section-energy
    flex-grow: 0
    flex-shrink: 0
    width: 64px
    text-align: right
    font-size: $font-size-s
    color: $color-muted-grey
    margin-left: auto

.meal-energy
    margin-right: $spacing-m

.meal-energy
  border-top: dashed 0.5px $color-muted-grey
  padding-top: $spacing-s

.dish-nutriscore
  flex-grow: 0
  flex-shrink: 0
  height: $font-size-m * 1.7
  margin-top: $font-size-m * (-0.3)
/*
  &.with-img
    display: flex
    flex-direction: column
    width: 86px
    min-width: 86px
    height: 86px
    min-height: 86px
    margin: 0px

    .dish-name
      user-select: none
      z-index: 2
      width: 72px
      padding: 0px 7px
      font-size: 12px
      font-weight: 500
      translate: 0px calc(-43px - 50%)
      // color: #ffffff
      // text-shadow: 0px 1px 1px #000000
      color: #000000
      text-shadow: 0px 1px 1px #ffffff

  &:hover
    cursor: pointer

  .dish-cover
    width: 72px
    height: 72px
    min-height: 72px
    margin: 7px
    border-radius: 10px
    overflow: hidden
    box-shadow: 0px 0px 1px #cccccc
    opacity: 0.8
    background-color: rgba(0,0,0,0.05)

    img
      width: 100%
      height: 100%
      object-fit: cover

  
  

  
  &.is-selected

    .dish-cover
      opacity: 1
      width: 80px
      height: 80px
      min-height: 80px
      margin: 0px
      border: solid 3px #000000
      border-radius: 13px



  &.not-selectable

    .dish-cover
      filter: grayscale(100%)
      opacity: 0.3
      cursor: default


    .dish-name
      color: #666666
      background-color: transparent
      text-shadow: none
      
  .dish-name-under

  .dish
    height: 126px

  .dish-name
    font-size: 11px
    color: #000000 !important
    text-shadow: none !important
    translate: none !important
    font-weight: 400
    text-overflow:ellipsis
    height: 40px
    overflow:hidden
    // Addition lines for n line or multiline ellipsis
    display: -webkit-box !important
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    white-space: normal
