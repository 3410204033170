@use 'sass:math'

@import './_variables'

.meal-level-buttons
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  gap: $spacing-s
  margin-left: $spacing-s
  user-select: none

.meal-level-button
  position: relative
  flex-grow: 0
  display: flex
  align-items: center
  text-align: center
  height: ($font-size-s + $spacing-s)
  border: solid 1px #000000
  border-radius: math.div($font-size-s + $spacing-s, 2)
  background-color: #ffffff
  color: #000000
  font-size: $font-size-s
  line-height: $font-size-s + 2px
  font-weight: 500
  padding: 0px $spacing-s 1px $spacing-s
  cursor: pointer

  .max-energy
    position: absolute
    visibility: hidden
    width: 50px
    text-align: center
    font-size: $font-size-s - 2px
    line-height: 1.2
    color: $color-muted-grey
    top: calc(100% + 3px)
    left: 50%
    transform: translate(-50%, 0%)

  &.is-selected
    background-color: #000000
    border-color: #000000
    color: #ffffff

    .max-energy
      visibility: visible
