@import './_variables.sass'

.video
  overflow: hidden
  iframe
    width: 100%

.embed-block-wrapper.embed-block-provider-YouTube
  border-radius: 15px
  overflow: hidden
 