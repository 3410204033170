@import './_variables'

body
  background-color: $color-background

.info-site
  font-family: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  font-weight: 400
  li
    font-size: 16px
    font-weight: 300
  .logo-head
    height: 26px
  .contact-us
    display: inline-block
    background-color: rgba(255, 255, 255, 0.9)
    height: 28px
    border-radius: 14px
    padding: 2px 16px 0px 16px
    text-decoration: none
    color: #000
    font-size: 16px
    font-weight: 300
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3)
    margin-top: -2px
    
  .hero-baseline
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    flex-direction: row
    font-weight: 400
    text-align: center
    margin-bottom: 24px
    margin-top: 24px
    div
      font-size: 16px
      text-transform: uppercase
      background-color: rgba(255, 255, 255, 0.8)
      padding: 2px 0px 1px 0px
      margin-bottom: 2px
  .block-tagline, .block-tagline-2
    background-size: cover
    background-position: center center
    margin-top: 57px
  .block-tagline
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/hero_background-min.jpg)
    height: 400px
    .hero-logo
      max-width: 90%
  .block-tagline-2
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url(/../public/assets/hero_background-min.jpg)
    padding-bottom: 24px
    .hero-logo
      max-width: 50%
  .background-image-1
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/couple_self-min.jpg)
  .background-image-2
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/chocolate_custard-min.jpg)
  .background-image-3
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/egg_ham_asparagus-min.jpg)
  .background-image-4
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/panna_cotta-min.jpg)
  .background-image-5
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/smiling_spoon-min.jpg)
  .background-image-6
    background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2)), url(/../public/assets/white_shirt_smartphone-min.jpg)
  .background-image-1, .background-image-2, .background-image-3, .background-image-4, .background-image-5, .background-image-6
    background-size: cover
    background-position: center center
    span
      bottom: 2rem
      left: 0
      right: 0
      font-weight: 600
      letter-spacing: 3px
  h1
    font-size: 2,625rem
  h2
    font-size: 25px
  h3
    font-size: 21px
  b, h3
    font-weight: 600
  p
    font-size: 16px
    font-weight: 300
  .block-exit
    background-color: rgba(255, 255, 255, 0.9)
    border-radius: 16px
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3)
    padding: 32px 32px

  // between sm md 66%
  @include media-breakpoint-between(sm, md)
    .home-block-content
      padding-bottom: 66.66%
  // above md, 33%
  @include media-breakpoint-up(md)
    .home-block-content
      padding-bottom: 33.33%
  // on small screen, 100%
  @include media-breakpoint-down(sm)
    .home-block-content
      padding-bottom: 100%

  @include media-breakpoint-up(xl)
  .block-tagline
    max-width: $container-max-width-xxl
    margin-right: auto
    margin-left: auto
