@import './_variables'

#profile-page
  display: flex
  flex-direction: column
  gap: 10px
  z-index: 10
  width: 400px
  margin: 0px auto 0px auto

.user-icon
  width: 80px
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.4))

.profile-selectors
  display: flex
  flex-direction: column
  gap: $spacing-m
  text-align: center

.how-it-works
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  height: ($font-size-s * 2)
  color: #000000
  font-size: $font-size-s
  line-height: $font-size-s + 2px
  font-weight: 500
  margin-top: $spacing-xl
  cursor: pointer
  .information-icon
    width: ($font-size-s + $spacing-s)
    margin: 0px $spacing-s -1px 0px
    background-color: #ffffff
    border-radius: 50%

.arrow-icon
  width: $spacing-m
  margin: $spacing-s 0px

.extended-information
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 0px $spacing-m
  div
    text-align: justify

.myfoodlife-compose-logo
  display: block
  margin: 0px auto $spacing-s auto
  width: 100px
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.3))
