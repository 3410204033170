@import './_variables'
@import './_mixins'
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap')

html, body
  overflow-x: hidden

body 
  position: relative
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: $main-text-size

html
  height: 100dvh

*
  box-sizing: border-box
  outline: none

// override bootstrap defaults
img, svg
  vertical-align: baseline

.main-container
  position: relative
  height: 100dvh
  width: 400px
  max-width: 100%
  margin: 0px auto
  padding: 6px
  display: flex
  flex-direction: column
  user-select: none
  overflow: clip

.restaurant-header, .profile-header
  flex-grow: 0
  display: flex
  height: $restaurant-header-height
  min-height: $restaurant-header-height
  align-items: center
  justify-content: center
  background-size: cover

.restaurant-header
  border-radius: $spacing-m
  
.menu-header, .summary-header
  display: flex
  align-items: center
  justify-content: center

.menu-header
  flex-direction: column
  justify-content: center
  margin: $spacing-l 0px $spacing-l 0px

.summary-header
  flex-direction: row
  align-items: center
  margin: $spacing-l 0px $spacing-xl 0px

.menu-title, .summary-title, .summary-greeting
  font-size: $font-size-s
  font-weight: bold
  text-transform: uppercase

.menu-subtitle
  font-size: $font-size-xs
  margin-top: $spacing-xs

.summary-exit
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  font-size: $font-size-s
  font-weight: 500
  margin: $spacing-xl $spacing-m $spacing-m $spacing-m
  padding: $spacing-s 0px
  border-style: dashed
  border-color: $color-light-grey
  border-width: 0.5px 0px 0.5px 0px
  .summary-greeting
    font-weight: bold

.restaurant-footer
  flex-grow: 0
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  height: $footer-height
  min-height: $footer-height
  position: relative

.summary-main-toggle-button-container
  display: flex
  align-content: center
  justify-content: center
  flex-grow: 1

#menu-sections-list, #summary-sections-list, #restaurant-not-found, #menu-not-found
  display: flex
  flex-direction: column
  flex-grow: 1
  min-height: 200px
  background-color: #ffffff
  border-radius: $spacing-m
  border: dotted 1px $color-transparent-black
  overflow-y: auto
  
  &::-webkit-scrollbar
    display: none

#summary-sections-list
  margin-top: $spacing-m

#restaurant-not-found, #menu-not-found
  align-items: center
  justify-content: center
  margin-top: $spacing-m
  margin-bottom: $spacing-m

.summary-main-toggle-button
  display: flex
  align-items: center
  height: ($font-size-m + $spacing-m)
  border: solid 1px #000000
  border-radius: ($font-size-m + $spacing-m) / 2
  background-color: #000000
  color: #ffffff
  font-size: $font-size-m
  line-height: $font-size-m + 2px
  font-weight: 500
  padding: 0px $spacing-m 1px $spacing-m
  cursor: pointer
  
.nb-dishes-selected
  flex-grow: 0
  display: flex
  flex-direction: row
  align-items: center
  background-color: #aaaaaa
  height: $font-size-xs + 2px
  font-size: $font-size-xs
  font-weight: bold
  color: #ffffff
  border-radius: 8px
  padding: 0px 6px

.summary-main-toggle-button
  .nb-dishes-selected
    margin: 0px 10px -1px -4px

#exit-button
  background-color: #000000
  color: #ffffff
  border-radius: 14px
  border: solid 1px #000000
  font-size: 14px
  font-weight: 400
  padding: 4px 15px 6px 15px
  min-width: 100px
  margin: 15px auto 0px auto
  text-align: center
  cursor: pointer
  position: relative

.inline
  display: flex
  flex-direction: row
  align-items: center
  gap: $spacing-s
  div
    text-align: left !important
  .myfoodlife-compose-logo
    margin-top: -7px

#spotlight-expanded-container
  position: absolute
  top: 0px
  left: 0px
  display: flex
  flex-direction: column
  justify-content: center
  z-index: 10
  width: 400px
  max-width: 100%
  background-color: rgba(0,0,0,0.75)
  height: 100dvh
  padding: $spacing-m
  #close-button
    width: 36px
    height: 36px
    border-radius: 18px
    background-color: rgba(0,0,0,0.75)
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 40px
    right: 42px
    z-index: 10
    .close-icon
      width: 24px