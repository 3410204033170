@import './_variables'

#menu
  flex-grow: 1
  display: flex
  flex-direction: column
  overflow-y: auto

.restaurant-logo
  margin: $spacing-m
  max-width: calc(100% - 2*$spacing-m)
  max-height: calc(120px - 2*$spacing-m)
  transition: max-width 0.3s ease-out, margin-right 0.25s ease-out
  &.spotlight-active
    max-width: calc(100% - 2*$spacing-m - $restaurant-header-height)
    margin-right: $restaurant-header-height + $spacing-m


.meal-level-invite
  margin: 0px $spacing-m $spacing-s $spacing-m
  text-align: center
  font-size: $font-size-s
  font-weight: bold
  text-transform: uppercase

.meal-level-and-profile-toggle
  margin: $spacing-m 0px $spacing-l 0px
  display: flex
  flex-direction: row
  align-items: flex-end
  justify-content: center
  gap: $spacing-s

.meal-energy-summary
  display: flex
  flex-direction: row
  align-items: flex-end
  margin-top: $spacing-s
  
.display-profile-toggle
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: ($font-size-s * 2)
  color: #000000
  font-size: $font-size-xs
  line-height: $font-size-s + 2px
  font-weight: 500
  padding: 0px $spacing-m 1px $spacing-m
  cursor: pointer
  .user-icon
    width: ($font-size-s + $spacing-s)
    filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.4))

.clear-meal
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: $font-size-xs
  font-weight: 500
  width: 70px
  position: absolute
  top: 12px
  left: 0px
  
.eraser-icon
  width: $spacing-m
