@import './_variables'
@import './Section.sass'
@import './Dish.sass'


#summary
  flex-grow: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  .bar-section-recap
    display: flex
    flex-direction: row
    .bar-section-name-and-container
      display: flex
      flex-direction: row
      flex-wrap: wrap

.meal-energy-label
  flex-grow: 1
  font-size: $font-size-s
  margin-left: $spacing-m
  margin-right: $spacing-s
  padding: 0px 0px 1px 0px
  text-align: right
  color: $color-muted-grey

.myfoodlife-copyright
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  font-size: $font-size-s
  margin-left: $spacing-l
  text-decoration: none
  color: #000000
  .myfoodlife-logo
    width: 90px

.energy-increment
  position: absolute
  width: 50px
  text-align: center !important
  font-size: $font-size-s - 2px
  font-weight: bold
  line-height: 1.2
  top: calc(100% + 3px)
  left: 50%
  transform: translate(-50%, 0%)
