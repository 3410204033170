@import './_variables'


.poll-message
  display: flex
  flex-direction: column
  gap: $spacing-m
  background-color: #ffffff
  padding: $spacing-s $spacing-m
  margin-bottom: $spacing-m
  border-radius: $spacing-m
  overflow: hidden
  animation: .4s ease-in-out forwards message-in

  &.left
    margin-right: $spacing-xl
  &.right
    margin-left: $spacing-xl
    padding: $spacing-m

  @keyframes message-in
    0%
      opacity: 0
      transform: translateY($spacing-l)
      max-height: 0px
      margin-top: -$spacing-m

    80%
      opacity: 1
    
    100%
      transform: translateY(0px)
      max-height: 100dvh
      margin-top: 0px

  .question-title
    font-size: $font-size-l
    font-weight: 600
    line-height: $font-size-l + 4px
    text-align: center
  .choices-list
    display: flex
    flex-direction: column
    gap: $spacing-m
    .choice
      background-color: #d21f3c
      color: #ffffff
      text-align: center
      font-size: $font-size-l
      font-weight: 500
      padding: $spacing-s $spacing-m
      border-radius: $spacing-s

